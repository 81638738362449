<template>
  <div class="relative">
    <div v-if="!isMobile" class="c-content">
      <img
        src="./s6/1.jpg"
        alt=""
        class="img absolute"
      >
      <div class="line-top absolute"></div>
      <div class="line-left absolute"></div>
      <div class="line-center absolute"></div>
      <div class="line-right absolute"></div>
      <div class="line-bottom absolute"></div>
      <div class="title absolute">
        德國RedDotAward金獎團隊<br />
        高質感+高坪效 國際住宅風景
      </div>
      <div class="desc absolute">
        展宜建築醇釀十五年，誠意手工精品〔詠喆〕<br />
        以Young & Urban時尚建築概念構思<br />
        創造的不只是一個居住容器<br />
        而是一種細緻的都會生活美學<br />
        在市中心好好、靜靜地享受生活的愉悅與感動<br /><br />
        忠孝東路一巷進 若隱於繁華城心<br />
        散步遊走信義計劃生活圈之間<br />
        永春站轉身 走入展宜用心蓋的小洋樓<br />
        三米六挑高都會空間，七層25戶單純住宅<br />
        動如脫兔擁繁華，靜若處子守靜寂<br />
        低調隱身在繁華裡的靜謐時尚
      </div>
    </div>
    <div
      v-else
       class="c-content">
      <div class="title absolute">
        德國RedDotAward金獎團隊<br />
        高質感+高坪效 國際住宅風景
      </div>
      <div class="desc absolute">
        展宜建築醇釀十五年，誠意手工精品〔詠喆〕，以Young & Urban時尚建築概念構思，創造的不只是一個居住容器，而是一種細緻的都會生活美學，在市中心好好、靜靜地享受生活的愉悅與感動。<br /><br />
        忠孝東路一巷進 若隱於繁華城心，散步遊走信義計劃生活圈之間，永春站轉身 走入展宜用心蓋的小洋樓，三米六挑高都會空間，七層25戶單純住宅，動如脫兔擁繁華，靜若處子守靜寂，低調隱身在繁華裡的靜謐時尚。
      </div>
      <div class="line-top absolute"></div>
      <div class="line-center absolute"></div>
      <div class="line-top2 absolute"></div>
      <img
        src="./s6/logoall_m.png"
        alt=""
        class="logoall absolute"
      >
      <img
        src="./s6/1_m.jpg"
        alt=""
        class="img absolute"
      >
      <div class="line-bottom absolute"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
.relative {
  // height: 100vh;
  height: size(1165);
  top: size(115);
  overflow: hidden;
  position: relative;
}
.c-content {
  //background-color: #fff;
  width: size(1690);
  height:100%;
  margin:0 0 0 size(115);
  position: relative;
}

.img {
  top: 0;
  left: 0;
  width: size(845);
  z-index: 1;
}

.title {
  font-size: size(41);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: #bb1a12;
  left:50%;
  padding: 0 0 0 size(61);
  top:0;
  display: flex;
  height: size(223);
  align-items: center;
}

.desc {
  top: size(223);
  font-size: size(30);
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #1a1311;
  left:50%;
  padding: 0 0 0 size(61);
  display: flex;
  height: size(1165 - 223);
  align-items: center;
}

.white-line {
  width: 100vw;
  height: size(20);
  background-color: #fff;
  top: size(62);
}

.line-top {
  background-color: #bf1b12;
  z-index: 1;
  width: size(960);
  height: 1px;
  top: size(223);
  left:50%;
}

.line-bottom {
  background-color: #bf1b12;
  z-index: 1;
  width: size(1805);
  right: 0;
  height: 1px;
  bottom: 0;
}

.line-center {
  background-color: #bf1b12;
  z-index: 1;
  left: 0;
  width: 1px;
  height: size(1165);
  right: 0;
  margin: 0 auto;
}

.line-left {
  background-color: #bf1b12;
  z-index: 1;
  left:0;
  top: 0;
  width: 1px;
  height: size(1165);
}

.line-right {
  background-color: #bf1b12;
  z-index: 1;
  right:0;
  top: size(223);
  width: 1px;
  height: size(941);
}
@media only screen and (max-width: 1440px) {
  .bg-img {
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .relative {
    height: size-m(858);
  }
  .c-content{height:100%;}

  .logoall {
    width: size-m(187);
    top: size-m(80.6);
    left:50%;
  }

  .img {
    top: size-m(467);
    left:size-m(-22);
    width: 100vw;
    z-index: 1;
  }
  .title {
    width: 100%;
    font-size: size-m(14);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align:center;
    color: #bb1a12;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: size-m(19);
    padding: 0;
    justify-content: center;
  }
  .desc {
    width: size-m(145);
    top:size-m(80.5);
    right: auto;
    left: 0;
    font-size: size-m(11.4);
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #1a1311;
    height:size-m(386);
    padding: 0;
  }

  .line-top {
    background-color: #bf1b12;
    z-index: 1;
    width: 100vw;
    height: 1px;
    top: size-m(80.5);
    right: 0;
    left:size-m(-22);
    }

  .line-center {
    background-color: #bf1b12;
    z-index: 1;
    left: 0;
    width: 1px;
    top: size-m(80.5);
    height: size-m(387);
    right: 0;
    margin: 0 auto;
  }

  .line-top2 {
    background-color: #bf1b12;
    z-index: 1;
    width: 100vw;
    height: 1px;
    top: size-m(467);
    right: 0;
  }
}
</style>
<script>
// @ is an alias to /src
import { isMobile } from '@/utils'

export default {
  name: 'section6',

  components: {},
  data() {
    return {
      isMobile,
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},
}
</script>
