export default {
  address: '台北市信義區永吉路278巷',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.707213659351!2d121.57194771500643!3d25.04400838396768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442aba2603c8119%3A0xcd80278bca1cd936!2zMTEw5Y-w5YyX5biC5L-h576p5Y2A5rC45ZCJ6LevMjc45be3!5e0!3m2!1szh-TW!2stw!4v1591582711907!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/GChDgBBxSccG7xCN7',
  phone: '02-2760-5888',
  fbLink: 'https://www.facebook.com/展宜詠喆-107034781055229/',
  fbMessage: 'https://m.me/107034781055229/',
  caseName: '展宜詠喆',
  indigatorLength: 7,

  houseInfos: [
    ['投資興建', '展宜建築開發股份有限公司'],
//    ['建築設計', '高鴻文建築師事務所'],
    ['建築設計', '三賦聯合建築師事務所'],
    ['公共空間', '創研空間設計'],
    ['建築代銷', '聯碩行銷股份有限公司'],
    ['建案位置', '台北市信義區永吉路278巷'],
    ['建照號碼', '108建字第0245號'],
  ],

  gtmCode: ['TB98S7F'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  } 
} 